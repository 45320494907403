import React from 'react';
import mixpanel from 'mixpanel-browser';

import AboveTheFold from "../components/AboveTheFold";
import AboutSection from "../components/AboutSection";
import SkillsSection from "../components/SkillsSection";
import ProjectsSection from "../components/ProjectsSection";
import Footer from "../components/Footer";
import CompaniesSection from "../components/CompaniesSection";


interface HomePageProps {
    // Define your prop types here (if any)
}

const HomePage: React.FC<HomePageProps> = (props) => {
    mixpanel.track('View Home');

    return (
        <div>
            <AboveTheFold />
            <AboutSection />
            <SkillsSection />
            {/*<CompaniesSection />*/}
            <ProjectsSection />
            <Footer />
        </div>
    );
}

export default HomePage;
